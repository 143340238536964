import React, {useState} from 'react';
import Button from '@mui/material/Button';
import { colors } from '../../constants/colors';
import Confetti from 'react-confetti-boom';
import { Tooltip } from '@mui/material';

const HomeContent = () => {
    const [showConfetti, setShowConfetti] = useState(true);
    const handleButtonClick = () => {
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 3000); 
      };

      const introParagraph = "I’m a 4th-year Computer Science student at Georgia Tech, concentrating in Artificial Intelligence and Human-Computer Interaction. " +
      "  With software development experience spanning tech, finance, research, defense, and hospitality, I’ve had the opportunity to work with diverse technologies and contribute to solving real-world problems. " +
      "  Skilled in Java, Python, C, C++, C#, and various web technologies, I’m looking to explore new opportunities where I can leverage my skills, drive meaningful impact, and continue growing as a developer!";
      
    return (
        <div style={{ fontFamily: "LondrinaSolid", margin: '20px'}}>
        <h2 style={{fontSize: '9vw', marginBottom: '0px'}}>HI</h2>
        <h2 style={{fontSize: '10vw', marginBottom: '10px', marginTop: '0px'}}>I'M ISABELLA</h2>
        <Tooltip title="Wait & Press for Confetti" arrow>
        <Button className="welcome-button" 
            onClick={handleButtonClick}
            style={{
                fontFamily: 'LondrinaSolid', 
                background: colors.blue, 
                color: colors.white,
                outline: 'none',
                borderRadius: '5px'
                }}
            >welcome to my page</Button></Tooltip>
            {showConfetti && (
        <Confetti
          mode="boom"
          particleCount={100}
          x={0.15}
          colors={['#ff577f', '#ff884b']}
        />
      )}
        <div style={ {marginTop: '60px'}}>
        <p style={{fontFamily: 'Georgia', width: '90%', lineHeight: '1.6', fontSize: '1vw', fontStyle: 'italic'}}>{introParagraph}</p>
      </div>
      </div>
    );
};

export default HomeContent;
