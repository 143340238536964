import { colors } from "../../constants/colors";

const SkillsGrid = () => {
    return(
        <div
            style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(6, 1fr)',
            border: `4px solid ${colors.purple}`, 
            borderRadius: '5px',
            gap: '10px',
            marginTop: '10px',
            maxWidth: '500px',
            justifyItems: 'center',
            marginLeft: 'auto', // This centers the grid itself horizontally
            marginRight: 'auto',
        }}
        >
                <img src={`${process.env.PUBLIC_URL}/java.png`} alt="Java" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/python.webp`} alt="Python" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/c.png`} alt="C" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/cplus.png`} alt="C++" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/csharp.svg`} alt="C#" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/js.jpg`} alt="JavaScript" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/aws.png`} alt="AWS" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/react.png`} alt="React" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/html.png`} alt="HTML/CSS" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/git.png`} alt="Git" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/azure.png`} alt="Azure" style={{ width: '60px', height: '60px' }} />
                <img src={`${process.env.PUBLIC_URL}/angular.png`} alt="Angular" style={{ width: '60px', height: '60px' }} />
            </div>
    )
};
export default SkillsGrid;