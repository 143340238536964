import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const AboutCarousel = () => {
    return (
        <Carousel 
            showThumbs={false} 
            autoPlay={false}
            infiniteLoop
            interval={3000} 
            showStatus={false}
            dynamicHeight
        >
            <div>
                <img src="/aboutpagepicture.jpg" alt="About Me" />
            </div>
            <div>
                <img src="/northern.jpg" alt="About Me" />
            </div>
            <div>
                <img src="/nyc.jpg" alt="About Me" />
            </div>
            <div>
                <img src="/luna.jpg" alt="About Me" />
            </div>
        </Carousel>
    );
};

export default AboutCarousel;
