import * as React from 'react';
import Fab from '@mui/material/Fab';
import { FileDownload } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import ExperienceHeader from './ExperienceHeader';
import EducationHeader from './EducationHeader';
import Timeline from './Timeline';
import EducationBody from './EducationBody';
import SkillsHeader from './SkillsHeader';
import SkillsGrid from './SkillsGrid';

const Resume = () => {


  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/IsabellaMartinResume.pdf'; 
    link.download = 'Isabella-Martin-Resume.pdf';
    link.click();
  };



  return(
    <div style={{ padding: '20px', marginBottom: '50px'}}>
      <Tooltip title="Download Resume" arrow>
        <Fab color="black" aria-label="edit" onClick={handleDownload}>
          <FileDownload />
        </Fab>
        </Tooltip>
        <EducationHeader/>
        <EducationBody/>
      <ExperienceHeader/>
      <Timeline/>
      <SkillsHeader/>
      <SkillsGrid/>
    </div>
  );
}
  

  export default Resume
