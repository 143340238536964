import * as React from 'react';
import { colors } from '../../constants/colors';

const ExperienceHeader = () => {
return(      
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h2 style={{ 
          fontFamily: 'LondrinaSolid', 
          fontSize: '2rem', 
          fontWeight: 'bold', 
          display: 'inline', 
          marginRight: '10px',
          color: colors.blue
        }}>
          EXPERIENCE
        </h2>
        <span style={{ 
          fontFamily: 'LondrinaSolid', 
          fontSize: '1.5rem', 
          fontStyle: 'italic', 
          color: colors.blue 
        }}>
          where i've worked
        </span>
    </div>
    )
};

export default ExperienceHeader