import React from 'react';
import { colors } from '../../constants/colors';
import AboutCarousel from './AboutCarousel';

const About = () => {

  return (
    <div
      className="flex"
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minHeight: '100vh',
        height: '100%',
        fontFamily: "LondrinaSolid",
      }}
    >
      {/* Left Side: Image */}
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'top',
          marginTop: '20px',
          marginBottom: '20px',
          marginLeft: '20px',
        }}
      >
        <AboutCarousel/>
      </div>

      {/* Right Side: Title and Text */}
      <div
        style={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          padding: '20px',
        }}
      >
        <div
          style={{
            backgroundColor: colors.purple,
            color: 'white',
            padding: '10px 15px',
            marginBottom: '20px',
            width: '95%',
          }}
        >
          <h1 style={{ margin: 0 }}>a little about me.</h1>
        </div>

        <div style={{fontFamily: 'Georgia'}}>
          <p style={{ marginBottom: '15px' }}>
          I first became interested in Computer Science and Software Development because of my love for math, logic, and problem-solving. After participating in robotics during high school, I was drawn to the logical thinking and creativity involved in writing and debugging code.
          </p>
          <p>
          During my senior year of high school, I worked as a Shift Manager at Playa Bowls. One of my tasks was calculating cash tips for all 35 employees—a time-consuming process that involved a complicated, three-page method. Around the same time, I was learning Python through YouTube videos, and it hit me: I could write code to automate these calculations.
          </p>
          <p>
          So, I did. My first version ran in the terminal and outputted a simple dictionary mapping each employee to their weekly tips. As I learned more, I enhanced the program—exporting data to CSV files and eventually building a user interface. This was my first real experience with software development, where I wrote code that solved a tangible problem.
          </p>
          <p>
          That excitement—the realization that code could solve real-world problems, and actually make life easier—has stuck with me ever since. 
          The desire to solve complex problems is part of what motivates me. I love the process of breaking down a problem, finding patterns, and building a solution that actually impacts someone :)
          </p>
          <p>Outside of school & work, I'm pretty busy. I love animals, especially dogs, so I enjoy fostering dogs and spending time with my two golden retrievers -- Luna & Lincoln. 
            In my free time, I love coffee shops, running, pilates, baking, reading, travelling, & sci-fi movies. 
   </p>
        </div>
      </div>

      {/* Spinner Animation */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default About;
