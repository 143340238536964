import * as React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { colors } from '../../constants/colors';

const Timeline = () => {
    const paragraphStyle = {
        fontStyle: 'italic',
        paddingLeft: '10px', 
        fontFamily: 'Georgia'
      };

return(    
<VerticalTimeline lineColor={colors.blue}>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ 
          background: '#fff',  
          color: colors.blue,  
          border: `2px solid ${colors.blue}`, 
        }}
        contentArrowStyle={{ borderRight: `7px solid  ${colors.blue}` }}
        date="NOVEMBER 2024 - SEPTEMBER 2024"
        iconStyle={{ 
          background: colors.blue, 
          border: `2px solid ${colors.blue}`,  // Ensures no white outline
          boxShadow: `0px 0px 0px rgba(0,0,255,0)` // Removes extra glow/shadow
        }}
        style={{fontFamily: 'LondrinaSolid'}}
        icon={<img src={`${process.env.PUBLIC_URL}/amazon.png`} alt="Amazon Logo" style={{ width: '100%', height: '100%'}} />}

      >
        <h3 className="vertical-timeline-element-title">SDE INTERN @ AMAZON</h3>
        <h4 className="vertical-timeline-element-subtitle">Seattle, WA</h4>
        <ul style={paragraphStyle}> 
          <li>Architected and deployed full-stack infrastructure, APIs, and a redesigned frontend for the Bingo Action Center, which guides the AWS billing system for new regions.</li>
          <li>Developed a full-stack search and filtering solution to improve query speed by 83% for over 10,000 action items.</li>
          <li>Implemented OpenSearch with a serverless collection using AWS CloudFormation, streaming DynamoDB action items via an ingestion pipeline to support complex queries and meet key stakeholder requirements.</li>
          <li>Upgraded the UI to support the new search handler, improving clients’ experience and eliminating timeouts.</li>
        </ul>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ 
          background: '#fff',  
          color: colors.blue,  
          border: `2px solid ${colors.blue}`, 
        }}
        contentArrowStyle={{ borderRight: `7px solid  ${colors.blue}` }}
        date="JUNE 2024 - AUGUST 2024"
        style={{fontFamily: 'LondrinaSolid'}}
        iconStyle={{ 
          background: colors.blue, 
          border: `2px solid ${colors.blue}`,
          boxShadow: `0px 0px 0px rgba(0,0,255,0)`
        }}
        icon={<img src={`${process.env.PUBLIC_URL}/microsoft.webp`} alt="MSFT Logo" style={{ width: '100%', height: '100%'}} />}
      >
        <h3 className="vertical-timeline-element-title">SWE INTERN @ MICROSOFT</h3>
        <h4 className="vertical-timeline-element-subtitle">Redmond, WA</h4>
        <ul style={paragraphStyle}> 
          <li>Designed and implemented a user growth initiative for Microsoft 365, increasing monthly active users (MAU) by 3%.</li>
          <li>Engineered a scalable, maintainable exit feature in C# and React, enabling future iterations.</li>
          <li>Conducted A/B/C testing to identify the most effective user experience by building custom UI components with integrated telemetry tracking.</li>
          <li>Owned the entire development lifecycle of the exit feature, including design, code, unit testing (Jest, React Testing Library), deployment, and performance monitoring.</li>
        </ul>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ 
          background: '#fff',  
          color: colors.blue,  
          border: `2px solid ${colors.blue}`, 
        }}
        contentArrowStyle={{ borderRight: `7px solid  ${colors.blue}` }}
        date="JUNE 2023 - AUGUST 2023"
        style={{fontFamily: 'LondrinaSolid'}}
        iconStyle={{ 
          background: colors.blue, 
          border: `2px solid ${colors.blue}`,
          boxShadow: `0px 0px 0px rgba(0,0,255,0)` 
        }}
        icon={<img src={`${process.env.PUBLIC_URL}/goldman.png`} alt="GS Logo" style={{ width: '100%', height: '100%'}} />}
      >
        <h3 className="vertical-timeline-element-title">SOFTWARE ENGINEERING SUMMER ANALYST @ GOLDMAN SACHS</h3>
        <h4 className="vertical-timeline-element-subtitle">New York City, NYC</h4>
        <ul style={paragraphStyle}> 
          <li>Created end to end notifications service for new Goldman Sachs Advisor Solutions application.</li>
          <li>Analyzed & then integrated core application APIs to develop content & triggers for user notifications.</li>
          <li>Spun up cloud server infrastructure for both lower & production environment using Terraform and Sky Deploy.</li>
          <li>Engineered automatic deployment pipeline for server code and wrote monitoring scripts for ongoing operations.</li>
          <li>Developed notification user interfaces, including creating a user settings section of the application to configure notifications</li>
          <li>Presented final product to Goldman Sachs senior management.</li>
        </ul>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ 
          background: '#fff',  
          color: colors.blue,  
          border: `2px solid ${colors.blue}`, 
        }}
        contentArrowStyle={{ borderRight: `7px solid  ${colors.blue}` }}
        date="JANUARY 2023 - MAY 2023 & JANUARY 2024 - MAY 2024"
        style={{fontFamily: 'LondrinaSolid'}}
        icon={<img src={`${process.env.PUBLIC_URL}/gtri.jpg`} alt="GTRI Logo" style={{ width: '100%', height: '100%', borderRadius: '50%'}} />}
      >
        <h3 className="vertical-timeline-element-title">SDE INTERN @ GEORGIA TECH RESEARCH INSTITUTE</h3>
        <h4 className="vertical-timeline-element-subtitle">Smryna, GA</h4>
        <ul style={paragraphStyle}> 
          <li>Full stack development work on the U.S. Navy’s FLINT (Force-Level Integration) decision support system with a focus on simulating naval aircraft operations.</li>
          <li>Devised Robot tests for frontend application, streamlining code reviews, and minimizing production bugs while also taking charge of leading SCRUM ceremonies on multiple occasions weekly.</li>
        </ul>
      </VerticalTimelineElement>

    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ 
          background: '#fff',  
          color: colors.blue,  
          border: `2px solid ${colors.blue}`, 
        }}
        contentArrowStyle={{ borderRight: `7px solid  ${colors.blue}` }}
        date="MAY 2023 - AUGUST 2023"
        style={{fontFamily: 'LondrinaSolid'}}
        icon={<img src={`${process.env.PUBLIC_URL}/he.webp`} alt="HE Logo" style={{ width: '100%', height: '100%', borderRadius: '50%'}} />}
      >
        <h3 className="vertical-timeline-element-title">SWE INTERN @ HOTEL EFFECTIVENESS</h3>
        <h4 className="vertical-timeline-element-subtitle">Alpharetta, GA</h4>
        <ul style={paragraphStyle}> 
          <li>Developed internal tools to allow non-technical users to import customer data that previously required escalation to the engineering team.</li>
        </ul>
      </VerticalTimelineElement>
    </VerticalTimeline>)
    };

    export default Timeline;