import * as React from 'react';
import { colors } from '../../constants/colors';

const EducationHeader = () => {
return(      
    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h2 style={{ 
          fontFamily: 'LondrinaSolid', 
          fontSize: '2rem', 
          fontWeight: 'bold', 
          display: 'inline', 
          marginRight: '10px',
          color: colors.pink
        }}>
          EDUCATION
        </h2>
        <span style={{ 
          fontFamily: 'LondrinaSolid', 
          fontSize: '1.5rem', 
          fontStyle: 'italic', 
          color: colors.pink 
        }}>
          what i've studied
        </span>
    </div>
    )
};

export default EducationHeader;