import React from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        justifyContent: 'space-between', // Ensures icons on left and text on right
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: 'black',
        color: 'white',
        position: 'fixed',
        bottom: 0,
        width: '100%',
      }}
    >
      {/* Left Side: Icons */}
      <Box>
        <a href="https://www.linkedin.com/in/isabella-a-martin/" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white' }}>
            <LinkedInIcon />
          </IconButton>
        </a>
        <a href="https://github.com/isabellaamartinn9?tab=projects" target="_blank" rel="noopener noreferrer">
          <IconButton sx={{ color: 'white' }}>
            <GitHubIcon />
          </IconButton>
        </a>
        <a href="mailto:isabellamartin@gatech.edu">
          <IconButton sx={{ color: 'white' }}>
            <EmailIcon />
          </IconButton>
        </a>
      </Box>

      {/* Right Side: "Made with love" */}
      <Typography 
        variant="body2" 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          fontFamily: 'LondrinaSolid',
          fontSize: '0.9rem'
        }}
      >
        made with   
        <FavoriteIcon sx={{ color: 'red', marginLeft: '5px', paddingRight: '40px', fontSize: '1rem'}} />
      </Typography>
    </Box>
  );
};

export default Footer;
