import * as React from 'react';
import { colors } from '../../constants/colors';

const EducationBody = () => {
    return(
        <div style={{ 
            background: '#fff', 
            border: `2px solid ${colors.pink}`, 
            padding: '10px', 
            width: '60%', 
            margin: '20px auto', 
            borderRadius: '10px',
            textAlign: 'left' 
          }}>
            <h3 style={{ 
              fontFamily: 'LondrinaSolid', 
              fontSize: '1.2rem', 
              color: colors.pink,
              marginBottom: '5px' // Reduced space between headings
            }}>
              GEORGIA INSTITUTE OF TECHNOLOGY, COLLEGE OF COMPUTING
            </h3>
            <h3 style={{ 
              fontFamily: 'LondrinaSolid',
              fontSize: '1rem', 
              color: colors.pink,
              marginTop: '0', // No extra space above the second heading
            }}>
              B.S. in Computer Science 
            </h3>
            <ul style={{ 
              fontFamily: 'Georgia', 
              fontSize: '.9rem', 
              fontStyle: 'italic',
              color: colors.pink, 
              paddingLeft: '10px', // Reduced indentation for bullet points
              marginTop: '5px' // Adjusted space between headings and bullet points
            }}>
                <li>Concentration in Artificial Intelligence & Human-Computer Interaction</li>
                <li>GPA: 3.80/4.0</li>
            </ul>
          </div>          
    )
};
export default EducationBody;
