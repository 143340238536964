import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Box, Modal } from '@mui/material';
import { colors } from '../../constants/colors';


const long_description_for_ml = "As part of my machine learning class, my group and I leveraged machine learning approaches mitigate unfair matchmaking in competitive video games." +
"  Players competiting in unbalanced matches can have a negative effect on user enjoyment, which can lead to reduced player retention." + 
"  In games where sales and revenue are based on adequate player retention, an unbalanced matchmaking system can lead to fewer sales. This can also have a negative affect on the reputation of the game developer."+ 
"  Our goal was to leverage machine learning approaches to reduce the quantity and severity of unfair matches, so that players will be more likely to continue supporting those games, thus increasing their longevity.";

const second_description_for_ml = "We implemented PCA dimensionality reduction on our chosen dataset, and we decided to implement an unsupervised learning method: K-means clustering, since it would be helpful in classifying players into clusters based on player attributes. We then applied the Naive Bayes classifier to complement our approach, providing a strong basis for initial predictions on player outcomes. This model excels in handling large datasets and making quick predictions, crucial for real-time matchmaking systems. We then implemented the Neural Networks model to further refine match outcome forecasts. This model utilizes deep learning techniques to uncover patterns that may not be readily apparent using simpler machine learning models or unsupervised methods like K-means."


const long_description_for_vip = "As part of my undergraduate research, I worked on a Vertically Integrated Project (VIP) called Autobots." + 
"  As part of Autobots, my first semester, I helped lead object recognitino efforts, where we leveraged AI and ML libraries in ROS to advance task-oriented autonomy for search and rescue robots." + 
"  In my subsequent semesters, I researched task command using Natural Language Processing to enhance marsupial robots for these search and rescue operations." + 
"  In order to enable NLP communication with the search and rescue Turtlebot, I developed a set of 'Robot Grammar' for the specific task commands. " + 
"  Following the work on task commands, I enabled voice recognition for the Turtlebot using the Google Cloud NLP ROS server. " +
" After achieving speech to text functionality, I worked on implementing ROS action clients in the NLP processing module.";


const cardData = [
  { title: 'Search & Rescue Autonomous Robots', 
    description: long_description_for_vip,
    image: "/autobots.jpg", 
    link: "https://drive.google.com/file/d/1QlPt7nHl0oun27S0jPlbHdRYlX6XnOQ-/view?usp=sharing",
    linkName:'VIP' 
  },
  { title: 'Fair Matchmaking in Competitive Video Games', 
    description: long_description_for_ml, 
    image: '/matchmaking.webp', 
    link: "https://github.gatech.edu/CS4641-Project/ML-Group-49",
    description_2: second_description_for_ml,
    linkName: 'ML Group 49 Project' },
  { title: 'Playa Bowls Tip Calculator', 
    description: 'Designed a tip calculator as a Shift Manager at Playa Bowls to decrease time spent dividing up cash tips per employee per shift per hour worked..', 
    image: "/playabowls.webp",
 },
 { title: 'Free User Awareness in Microsoft365', 
  description: 'A user growth initiative for Microsoft 365, increasing monthly active users (MAU) by 3%..', 
  image: '/m365.png',
 },
 { title: 'OpenSearch in AWS Bingo Action Center', 
  description: 'Architected and deployed full-stack infrastructure, APIs, and a redesigned frontend for the Bingo Action Center, which guides the AWS billing system for new regions.', 
  image: "/code.jpg", 
},
  { title: 'Two-Factor Authentication for GSAS', 
    description: 'Created end to end notifications service for new Goldman Sachs Advisor Solutions application with two factor authentication enabled.', 
    image: '/twofactor.png' },
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  outline: 'none'
};

const CardGrid = () => {
  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleOpen = (card) => {
    setSelectedCard(card);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCard(null);
  };

  return (
    <Box display="flex" justifyContent="center" minHeight="100vh" p={2} sx={{ overflowY: 'auto' }}>
      <Grid container spacing={2} justifyContent="center" maxWidth="1100px"  sx={{ marginBottom: '120px' }}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} display="flex" justifyContent="center">
            <Card sx={{ width: 300, boxShadow: 3, borderRadius: '5px' }}>
              <CardMedia
                component="img"
                height="140"
                image={card.image}
                alt={card.title}
              />
              <CardContent style={{ fontFamily: "LondrinaSolid" }}>
                <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: "LondrinaSolid" }}>
                  {card.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {card.description.substring(0, 60)}...
                </Typography>
              </CardContent>
              <Button
                size="small"
                sx={{
                  m: 2,
                  backgroundColor: colors.yellow,
                  fontFamily: "LondrinaSolid",
                  color: 'white',
                  '&:hover': { backgroundColor: '#8E24AA' }
                }}
                onClick={() => handleOpen(card)}
              >
                Learn More
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Modal */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={modalStyle}>
          {selectedCard && (
            <>
              <Typography id="modal-title" variant="h6" component="h2" style={{ fontFamily: "LondrinaSolid" }}>
                {selectedCard.title}
              </Typography>
              <Typography sx={{mt: 2}}>
                Project Overview:
              </Typography>
              {selectedCard.linkName ? (
                <a href={selectedCard.link} target="_blank" rel="noopener noreferrer" 
                style={{ 
                  color: colors.yellow, 
                  wordWrap: 'break-word', 
                  overflowWrap: 'break-word', 
                  display: 'block',
                  maxWidth: '100%' 
                }}>
                  {selectedCard.link}
                </a>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No Link Available
                </Typography>
              )}
              <Typography id="modal-description" sx={{ mt: 2 }}>
                {selectedCard.description}
              </Typography>
              {selectedCard.description_2 && <Typography id="modal-description" sx={{ mt: 2 }}>
                {selectedCard.description_2}
              </Typography>
              }
              <Button
                onClick={handleClose}
                sx={{
                  mt: 2,
                  backgroundColor: colors.yellow,
                  color: 'white',
                  '&:hover': { backgroundColor: '#8E24AA' }
                }}
              >
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default CardGrid;
