import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './AppBar/Header';
import Footer from './AppBar/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Resume from './components/Resume/Resume';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header/>
        <main className="content">
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/about" element={<About/>}/>
          <Route path="/resume" element={<Resume/>}/>
          <Route path="/projects" element={<Projects/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
        </main>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
