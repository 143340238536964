// Header.js
import React from "react";
import { Link } from 'react-router-dom';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { colors } from "../constants/colors";
import { blue } from "@mui/material/colors";

const Header = () => {


  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'black', fontFamily: 'LondrinaSolid'}}>
      <Tabs>
      <Tab label="ISABELLA MARTIN" component={Link} to="/" sx={{ textTransform: "none" }} style={{
        color: 'white', 
        fontFamily: 'LondrinaSolid',
        fontSize: '3vw',
        }} />
      </Tabs>
      <div style={{ flex: '1' }}></div>
      <Tabs value={false} variant="fullWidth" indicatorColor="secondary" textColor="secondary">
        <Tab label="about" component={Link} to="/about" sx={{ textTransform: "none" }} style={{
            color: 'white',
            fontFamily: "LondrinaSolid",
            fontSize: "2vw",
            }}></Tab>
        <Tab label="resume" component={Link} to="/resume" sx={{ textTransform: "none" }} style={{color: 'white', fontFamily: "LondrinaSolid", fontSize: "2vw"}}/>
        <Tab label="projects" component={Link} to="/projects" sx={{ textTransform: "none" }} 
            style={{
                color: 'white', 
                fontFamily: "LondrinaSolid", 
                fontSize: "2vw"
            }}/>
        <Tab label="contact" component={Link} to="/contact" sx={{ textTransform: "none" }} 
            style={{
                color: 'white', 
                fontFamily: "LondrinaSolid",
                fontSize: "2vw"
            }}/>
      </Tabs>
    </div>
  );
};

export default Header;