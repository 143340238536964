import { colors } from "../../constants/colors";
import ContactForm from "./ContactForm";

const Contact = () => {

    const headingStyle = {fontSize: '10vw', margin: '20px', color: colors.blue};

    const formStyle = { flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' };

    const sectionStyle = { 
        flex: 1, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        marginRight: '20px'
    }; 

    const pageStyle = { display: 'flex', flexDirection: 'row', width: '100%', height: '100%', fontFamily: "LondrinaSolid"};

    return (
        <div 
          className="flex" 
          style={pageStyle}
        >
          <div 
            style={sectionStyle}
          >
            <h2 style={headingStyle}>Let's get in touch? </h2>
          </div>
          <div style={formStyle}>
            <ContactForm/>
          </div>
        </div>
      );
    };

export default Contact;