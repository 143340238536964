import { colors } from "../../constants/colors";
import CardGrid from "./CardGrid";

const Projects = () => {
        return(
            
        <div style={{ fontFamily: "LondrinaSolid"}}>
            <div
                style={{
                    backgroundColor: colors.yellow,
                    alignContent: 'center',
                    marginLeft: '50px',
                    marginRight: '50px',
                    justifyContent: 'center',
                    color: 'white',
                    margin: '20px auto',       // Center horizontally          // 95% of the middle width
                    borderRadius: 5,
                    padding: '10px 0',         // Vertical padding for better spacing
                }}
            >
            <h1 style={{ marginLeft: '10px'}}>explore some of my past projects.</h1>
        </div>
            <CardGrid></CardGrid>
        </div>
    )
}

export default Projects;