import React, { useState } from 'react';
import { colors } from '../../constants/colors';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    subject: '',
  });

  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      subject: '',
    });
  };

  return (
    <div>
      {showAlert && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Your message has been successfully submitted!
        </Alert>
      )}

      <form 
        onSubmit={handleSubmit} 
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          fontFamily: 'LondrinaSolid',
          marginTop: '50px'
        }}
      >
        <div style={{ display: 'flex', gap: '10px', borderRadius: '5px' }}>
          <input
            type="text"
            name="firstName"
            placeholder="first name"
            value={formData.firstName}
            onChange={handleChange}
            style={{ flex: 1, padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid' }}
          />
          <input
            type="text"
            name="lastName"
            placeholder="last name"
            value={formData.lastName}
            onChange={handleChange}
            style={{ flex: 1, padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid' }}
          />
        </div>
        <input
          type="email"
          name="email"
          placeholder="email"
          value={formData.email}
          onChange={handleChange}
          style={{ padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid' }}
        />
        <input
          type="text"
          name="subject"
          placeholder="subject"
          value={formData.subject}
          onChange={handleChange}
          style={{ padding: '10px', fontSize: '16px', fontFamily: 'LondrinaSolid' }}
        />
        <textarea
          name="message"
          placeholder="message"
          value={formData.message}
          onChange={handleChange}
          style={{ padding: '10px', fontSize: '16px', height: '100px', fontFamily: 'LondrinaSolid' }}
        ></textarea>
        <button
          type="submit"
          style={{
            padding: '10px',
            backgroundColor: colors.blue,
            fontFamily: 'LondrinaSolid',
            color: 'white',
            fontSize: '16px',
            border: 'none',
            cursor: 'pointer',
            borderRadius: '5px'
          }}
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
